export default function PopupBody({
  icon,
  title,
  children,
}: {
  icon: JSX.Element;
  title: string;
  children: React.ReactNode;
}) {
  return (
    <div className="flex items-center">
      <div className="pr-2">{icon}</div>
      <div className="flex flex-col">
        <h1 className="font-semibold">{title}</h1>
        <div className="flex flex-col text-neutral-700 dark:text-neutral-200 text-sm">
          {children}
        </div>
      </div>
    </div>
  );
}
