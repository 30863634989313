import Accordion from 'generic/components/Accordion';
import Card from 'generic/components/Card';
import Button from 'generic/components/Form/Button';
import PrivateWrapper from 'generic/components/PrivateWrapper';
import Tooltip from 'generic/components/Tooltip';
import Transition from 'generic/components/Transition';
import type { SensorsStatusQuery } from 'graphql/types';
import useStore from 'model/store';
import { FaCircleNotch } from 'react-icons/fa';
import {
  HiOutlineCheckCircle,
  HiOutlineTrash,
  HiOutlineXCircle,
  HiShieldExclamation,
} from 'react-icons/hi2';
import {
  FormattedMessage,
  type IntlMessageKeys,
  useIntl,
} from 'translations/Intl';
import { formattedDistance } from 'utils/date';
import { HasuraPermissions } from 'utils/graphql/useHasuraHeaders';
import renderSensorIcon from 'utils/renderSensorIcon';
import SensorChart from './components/SensorChart';

interface SensorCardProps {
  sensor: SensorsStatusQuery['Sensors'][number];
  isDeleteLoading: number | null;
  setSensorToRemove: (
    sensor: SensorsStatusQuery['Sensors'][number] | null,
  ) => void;
}

export default function SensorCard({
  sensor,
  setSensorToRemove,
  isDeleteLoading,
}: SensorCardProps) {
  const intl = useIntl();
  const userRoles = useStore((state) => state.user)?.roles;

  const deskRelations = sensor.Desk?.Name;
  const roomRelations = [
    ...new Set(sensor.RoomSensors.map((rS) => rS.Room.Name)),
  ];
  const isActive = deskRelations || roomRelations.length > 0;

  return (
    <Card
      data-test-id={`sensor-card-${sensor.SensorType.Name}`}
      className="flex flex-wrap w-full px-2 py-2 md:p-2 col-span-2 md:col-span-1"
    >
      <div className="flex flex-col flex-wrap w-full">
        <div className="flex justify-between">
          <div className="flex gap-2 items-center flex-wrap">
            <Tooltip
              content={
                <div className="relative p-2 bg-primary-500 text-white rounded">
                  {renderSensorIcon(
                    sensor.SensorType.Name,
                    'size-5 text-white',
                  )}
                </div>
              }
            >
              <p>{sensor.SensorType.Name}</p>
            </Tooltip>
            <div>
              <span data-test-id={`${sensor.SensorType.Name}-${sensor.Index}`}>
                <FormattedMessage
                  id={sensor.SensorType.Name as IntlMessageKeys}
                />
              </span>{' '}
              {sensor.Index > 0 && sensor.Index}
            </div>
            <div className="flex">
              {!isActive ? (
                <Tooltip
                  content={
                    <p>
                      <HiOutlineXCircle
                        data-test-id="sensor-inactive"
                        className="size-4 text-neutral-500"
                      />
                    </p>
                  }
                >
                  <FormattedMessage id="Not active" />
                </Tooltip>
              ) : (
                <Tooltip
                  content={
                    <p>
                      <HiOutlineCheckCircle
                        data-test-id="sensor-active"
                        className="size-4 text-green-500"
                      />
                    </p>
                  }
                >
                  <FormattedMessage id="Active" />
                </Tooltip>
              )}
              {sensor.IsPrivate && (
                <Tooltip
                  content={
                    <p className="pl-1">
                      <HiShieldExclamation
                        data-test-id="sensor-private"
                        className="size-4 text-neutral-600"
                      />
                    </p>
                  }
                >
                  <FormattedMessage id="Private sensor" />
                </Tooltip>
              )}
            </div>
          </div>
          <PrivateWrapper roleRequired={HasuraPermissions.DELETE_SENSOR}>
            <Button
              id={`delete-floor-${Number}`}
              className="size-8 bg-primary-200 text-primary-500 hover:bg-primary-400 hover:text-white focus:outline-none rounded-full flex items-center justify-center"
              onClick={() => setSensorToRemove(sensor)}
              title={intl.formatMessage({
                id: 'Remove',
              })}
            >
              {isDeleteLoading && isDeleteLoading === sensor.Id ? (
                <FaCircleNotch className="size-5 animate-spin text-primary-500" />
              ) : (
                <HiOutlineTrash className="size-5" />
              )}
            </Button>
          </PrivateWrapper>
        </div>

        <div className="flex flex-col flex-wrap text-sm gap-1 pt-1">
          <Transition
            show={
              !sensor.IsPrivate ||
              // Allow "God" to be all seeing
              userRoles?.includes(HasuraPermissions.READ_ALL)
            }
          >
            <div
              className="flex flex-wrap"
              data-test-id={`value-${sensor.SensorType.Name}-${sensor.Index}`}
            >
              <FormattedMessage id="Value" />: {sensor.Value}{' '}
              {sensor.SensorType.Unit} (
              {formattedDistance(new Date(sensor.UpdatedAt))})
            </div>
          </Transition>
          {deskRelations && (
            <div>
              <FormattedMessage id="Desk" />: {deskRelations}
            </div>
          )}
          {roomRelations.length > 0 && (
            <div className="flex flex-wrap">
              <FormattedMessage
                id={roomRelations.length > 1 ? 'Rooms' : 'Room'}
              />
              : {roomRelations.sort((a, b) => a.localeCompare(b)).join(', ')}
            </div>
          )}
          {sensor.MqttBeacon.Floor?.Number && (
            <>
              <FormattedMessage id="Floor" />
              {': '}
              <FormattedMessage
                id="Building Floor"
                values={{
                  building: sensor.MqttBeacon.Floor.Building.Name,
                  number: sensor.MqttBeacon.Floor.Number,
                }}
              />
            </>
          )}
          <Transition
            show={
              !sensor.IsPrivate ||
              // Allow "God" to be all seeing
              userRoles?.includes(HasuraPermissions.READ_ALL)
            }
          >
            <Accordion
              title={
                <p className="text-sm font-medium">
                  <FormattedMessage id="Historical values over the last 2 weeks" />
                </p>
              }
              chevronClassName="!size-4"
            >
              <div className="h-24 relative">
                <SensorChart sensorId={sensor.Id} />
              </div>
            </Accordion>
          </Transition>
        </div>
      </div>
    </Card>
  );
}
