import { Tooltip } from '@visx/tooltip';
import Card from 'generic/components/Card';
import PopupBody from 'generic/components/layout/PopupBody';
import BaseLayer from 'generic/layers/BaseLayer';
import GeometryLayer from 'generic/layers/GeometryLayer';
import type TypedFeature from 'generic/layers/TypedFeature';
import {
  type BuildingsTopologyQuery,
  type FloorPartsFragment,
  type RoomPartsFragment,
  useFloorQuery,
} from 'graphql/types';
import Map from 'mda2-frontend/src/generic/components/Map';
import useStore from 'model/store';
import type { Feature, MapBrowserEvent } from 'ol';
import OLMap from 'ol/Map';
import type { Geometry } from 'ol/geom';
import type VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import { useEffect, useState } from 'react';
import { HiOutlineCube } from 'react-icons/hi2';
import { useIntl } from 'translations/Intl';
import getColor from 'utils/getColor';
import useDeviceDetect from 'utils/useDeviceDetect';

type Room =
  BuildingsTopologyQuery['Buildings'][number]['Floors'][number]['Rooms'][number];

type RoomFeatureType = TypedFeature<Room, Geometry>;

interface MapHighlightProps {
  rooms: Room[];
  floor: FloorPartsFragment;
  hoveredRoomId?: number;
  onRoomSelect?: (r: RoomPartsFragment) => void;
}

export default function MapHighlight({
  rooms,
  floor,
  hoveredRoomId,
  onRoomSelect,
}: MapHighlightProps) {
  const { isMobile } = useDeviceDetect();
  const intl = useIntl();
  const room = useStore((state) => state.userSettings.room);
  const setRoom = useStore((state) => state.userApi.setRoom);
  const building = useStore((state) => state.userSettings.building);
  const [selectedFeature, setSelectedFeature] = useState<
    RoomFeatureType | undefined
  >();

  const [baseLayer] = useState(new BaseLayer());
  const [roomLayer] = useState(
    new GeometryLayer<Room>({
      style: (feat, _, hoveredFeat) => [
        new Style({
          stroke: new Stroke({
            color: getColor(
              'YELLOW',
              feat.getGeometry() === hoveredFeat?.getGeometry() ? '.9' : '.8',
            ),
            lineDash:
              feat.getGeometry() === hoveredFeat?.getGeometry()
                ? [5, 5]
                : undefined,
            width: feat.getGeometry() === hoveredFeat?.getGeometry() ? 3 : 2,
          }),
          fill: new Fill({
            color: getColor(
              'YELLOW',
              feat.getGeometry() === hoveredFeat?.getGeometry() ? '.9' : '.3',
            ),
          }),
        }),
      ],
    }),
  );

  const [layers] = useState([baseLayer, roomLayer]);
  const [map] = useState(new OLMap({}));
  const [hoveredFeature, setHoveredFeature] = useState<
    RoomFeatureType | undefined
  >(undefined);

  const [{ data: floorImageData, fetching: imageLoading }] = useFloorQuery({
    variables: {
      BuildingName: building?.Name,
      FloorNumber: floor?.Number,
    },
    pause: typeof floor?.Number !== 'number' || !building?.Name,
  });

  const showFeatureInfo = (
    evt: MapBrowserEvent<PointerEvent>,
    feat?: RoomFeatureType,
  ) => {
    if (feat) {
      setHoveredFeature(feat);
      roomLayer.hoveredFeature = feat;
      roomLayer.olLayer.changed();
    } else {
      setHoveredFeature(undefined);
      roomLayer.hoveredFeature = (
        (
          roomLayer.olLayer.getSource() as VectorSource<Feature<Geometry>>
        ).getFeatures() as RoomFeatureType[]
      ).find(
        (r) =>
          r.getProperties().Id ===
          (onRoomSelect ? selectedFeature?.getProperties().Id : room?.Id),
      );
      roomLayer.olLayer.changed();
    }

    evt.map.getTargetElement().style.cursor = feat ? 'pointer' : '';
  };

  useEffect(() => {
    if (floorImageData?.Floors[0]?.Image) {
      baseLayer.setImage(floorImageData.Floors[0]?.Image ?? '');
    }
  }, [baseLayer, floorImageData?.Floors[0]?.Image]);

  useEffect(() => {
    if (rooms) {
      roomLayer.setFeatures(rooms);
    } else {
      roomLayer.setFeatures([]);
    }
  }, [rooms, roomLayer]);

  useEffect(() => {
    if (hoveredRoomId) {
      roomLayer.hoveredFeature = (
        (
          roomLayer.olLayer.getSource() as VectorSource<Feature<Geometry>>
        ).getFeatures() as RoomFeatureType[]
      ).find((r) => r.getProperties().Id === hoveredRoomId);
      roomLayer.olLayer.changed();
    }
  }, [hoveredRoomId, roomLayer]);

  return (
    <>
      <Map<RoomFeatureType>
        enableZoomButton={false}
        map={map}
        className="h-48"
        isLoadingFeatures={imageLoading}
        layers={layers}
        onFeaturesClick={(features) => {
          // Do not set the room in local storage for using it in the chartcomparer component
          if (onRoomSelect && features[0]?.getProperties()) {
            onRoomSelect(features[0].getProperties());
            setSelectedFeature(features[0]);
          } else {
            setRoom(features[0]?.getProperties());
          }
        }}
        onFeaturesHover={(hoveredFeatures, evt) => {
          const [feat] = hoveredFeatures.map((hF) => hF.feature);
          showFeatureInfo(evt, feat);
        }}
        renderTooltip={(props) => {
          if (!hoveredFeature) return undefined;

          if (isMobile) {
            return (
              <Card className="absolute bottom-0 right-0 left-0 z-20">
                <PopupBody
                  title={intl.formatMessage({ id: 'Room' })}
                  icon={<HiOutlineCube className="size-5" />}
                >
                  {hoveredFeature.getProperties().Name}
                </PopupBody>
              </Card>
            );
          }
          return (
            <Tooltip {...props}>
              <PopupBody
                title={intl.formatMessage({ id: 'Room' })}
                icon={<HiOutlineCube className="size-5" />}
              >
                {hoveredFeature.getProperties().Name}
              </PopupBody>
            </Tooltip>
          );
        }}
      />
    </>
  );
}
