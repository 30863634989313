import Transition from 'mda2-frontend/src/generic/components/Transition';
import { useState } from 'react';
import { HiOutlineChevronRight } from 'react-icons/hi2';

import Button from '../Form/Button';

interface AccordionProps {
  initialStateOpen?: boolean;
  title: string | JSX.Element;
  children: JSX.Element;
  dataTestId?: string;
  sticky?: boolean;
  chevronClassName?: string;
}

export default function Accordion({
  initialStateOpen = false,
  title,
  children,
  dataTestId = 'accordion-button',
  sticky,
  chevronClassName,
}: AccordionProps) {
  const [open, setOpen] = useState(initialStateOpen);

  return (
    <div>
      <Button
        data-test-id={dataTestId}
        onClick={() => setOpen(!open)}
        className={`flex align-middle items-center print:hidden z-0 ${sticky ? 'sticky top-0 mix-blend-difference' : ''}`}
      >
        <p className="track-wider font-bold text-lg text-left dark:text-white text-neutral-800">
          {title}
        </p>
        <HiOutlineChevronRight
          className={`size-6 ${chevronClassName ?? ''} transition-all ${open ? 'rotate-90' : ''}`}
        />
      </Button>
      <Transition show={open} appear>
        {children}
      </Transition>
    </div>
  );
}
