import type { JsonGeometry } from 'common/jsonGeometry';
import PopupBody from 'generic/components/layout/PopupBody';
import type TypedFeature from 'generic/layers/TypedFeature';
import format from 'mda2-frontend/src/utils/format';
import type { Polygon } from 'ol/geom';
import { HiCpuChip } from 'react-icons/hi2';
import { FormattedMessage } from 'translations/Intl';

export type AssetHistoryFeatureType = TypedFeature<Asset, Polygon>;
export type Asset = {
  id: number;
  floorId: number;
  now: boolean;
  name: string;
  date: Date;
  Geometry: JsonGeometry;
};

interface AssetHistoryPopupProps {
  hoveredFeature: AssetHistoryFeatureType;
}

export default function AssetHistoryPopup({
  hoveredFeature,
}: AssetHistoryPopupProps): JSX.Element {
  const { date, name } = hoveredFeature.getProperties();
  const timestamp = format(new Date(date), 'PPp');

  return (
    <PopupBody
      title={name}
      icon={
        <div className="bg-primary-300 flex-shrink-0 flex items-center justify-center size-8 rounded-full mr-2">
          <HiCpuChip className="mx-auto size-6 text-primary-700" />
        </div>
      }
    >
      <div className="flex flex-col">
        <div className="flex items-center">
          <FormattedMessage id="Timestamp" />: {timestamp}
        </div>
      </div>
    </PopupBody>
  );
}
